import axios, { AxiosError, AxiosRequestConfig } from "axios";
import cookies, { COOKIE_USER_TOKEN, get_user_token } from '../util/cookies';

export const with_auth_check = (callback: (value: AxiosError) => any) => {
    return (error: AxiosError) => {
        if (error.response?.status === 401) {
            cookies.remove(COOKIE_USER_TOKEN);
        }
        return callback(error);
    }
}

export const with_auth = (config: AxiosRequestConfig = {}) => {
    return { ...config,withCredentials: true, headers: { ...config.headers, Authorization: `Bearer ${get_user_token() || ''}` }};
}

export const get_with_auth = <T, S = T>(url: string, params = {}, map: (t: T) => S = (t) => t as unknown as S) => {
    return (): Promise<S[]> => axios.get<T[]>(url, with_auth(params)).then(response => {
        if (response.status === 200) {
            return response.data.map(map);
        }
        return [];
    }).catch(with_auth_check(error => {
        console.error(error);
        return [];
    }))
}